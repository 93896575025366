import React from 'react'

import Picture from 'src/components/Picture'
import wireframesMobile from 'src/images/optimized/png/linguamill/wireframesMobile.png'
import wireframesMobileRetina from 'src/images/optimized/png/linguamill/wireframesMobile@2x.png'
import wireframesDesktop from 'src/images/optimized/png/linguamill/wireframesDesktop.png'
import wireframesDesktopRetina from 'src/images/optimized/png/linguamill/wireframesDesktop@2x.png'

import styles from './styles.module.scss'

const wireframesImg = {
    source: [
        {
            srcSet: wireframesMobile,
            retina: wireframesMobileRetina,
            media: '(max-width: 699px)',
        },
        {
            srcSet: wireframesDesktop,
            retina: wireframesDesktopRetina,
            media: '(min-width: 700px)',
        },
    ],
    src: wireframesDesktop,
    retina: wireframesDesktopRetina,
    alt: 'Linguamill wirefremes example',
}

const Wireframes = () => {
    return (
        <section className={styles.wireframes}>
            <div className="container">
                <h4 className={styles.mainTitle}>Wireframes</h4>
                <div className={styles.content}>
                    <Picture pic={wireframesImg} />
                </div>
            </div>
        </section>
    )
}

export default Wireframes
