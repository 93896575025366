import React from 'react'
import classNames from 'classnames'

import SvgSpriteIcon from 'src/components/SvgSprite/SvgSpriteIcon'

import styles from './styles.module.scss'

const Design = () => {
    const sectionContainerClass = classNames('container', styles.design)
    const singleLogoClass = classNames(styles.logo, styles.singleLogo)
    const usageLogoClass = classNames(styles.logo, styles.usageLogo)
    const smallLogoClass = classNames(styles.logo, styles.small)
    const mediumLogoClass = classNames(styles.logo, styles.medium)
    const largeLogoClass = classNames(styles.logo, styles.large)

    return (
        <section className={sectionContainerClass}>
            <h4 className={styles.mainTitle}>Visual Design</h4>
            <h5 className={styles.secondaryTitle}>App Icon &amp; Logo</h5>
            <div className={styles.logoContainer}>
                <SvgSpriteIcon name="linguaAppLogo" className={styles.appLogo} />
                <div className={styles.logoWrapper}>
                    <SvgSpriteIcon name="linguaTextLogo" className={styles.appTextLogo} />
                    <SvgSpriteIcon name="linguaLogo" className={singleLogoClass} />
                </div>
            </div>
            <h5 className={styles.secondaryTitle}>Logo Usage</h5>
            <div className={styles.logoContainer}>
                <div className={styles.logoWrapper}>
                    <SvgSpriteIcon name="linguaLogo" className={usageLogoClass} />
                    <SvgSpriteIcon name="linguaDrawingLogo" className={styles.drawingLogo} />
                </div>
                <div className={styles.trippleLogo}>
                    <SvgSpriteIcon name="linguaLogo" className={smallLogoClass} />
                    <SvgSpriteIcon name="linguaLogo" className={mediumLogoClass} />
                    <SvgSpriteIcon name="linguaLogo" className={largeLogoClass} />
                </div>
                <div className={styles.logoWrapper}>
                    <SvgSpriteIcon name="linguaDoubleLogo" className={styles.doubleLogo} />
                    <SvgSpriteIcon name="linguaQuadrLogo" className={styles.quadrLogo} />
                </div>
            </div>
            <div className={styles.flexContainer}>
                <div>
                    <h5 className={styles.secondaryTitle}>Color &amp; Typography</h5>
                    <div className={styles.logoContainer}>
                        <div className={styles.colorTypography} />
                    </div>
                </div>
                <div>
                    <h5 className={styles.secondaryTitle}>Modular Grid</h5>
                    <div className={styles.logoContainer}>
                        <div className={styles.grid}>
                            <SvgSpriteIcon
                                name="linguaTextDrawing"
                                className={styles.textDrawingLogo}
                            />
                            <SvgSpriteIcon name="linguaTextLogo" className={styles.textLogo} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Design
