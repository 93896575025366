import React from 'react'
import classNames from 'classnames'

import styles from './styles.module.scss'

const Guideline = () => {
    const sectionContainerClass = classNames('container', styles.guideline)
    return (
        <section className={sectionContainerClass}>
            <h4 className={styles.mainTitle}>Guideline</h4>
            <p className={styles.text}>
                A detailed UI Kit was created. It’s provides for the smallest details.
            </p>
            <div className={styles.image} />
        </section>
    )
}

export default Guideline
