import React from 'react'
import classNames from 'classnames'

import Profile from './Profile'
import SvgSpriteIcon from 'src/components/SvgSprite/SvgSpriteIcon'

import styles from './styles.module.scss'

const Interview = () => {
    const containerSectionClass = classNames('container', styles.interview)
    return (
        <section className={containerSectionClass}>
            <h4 className={styles.mainTitle}>Persona&apos;s Interview</h4>
            <div className={styles.wrapper}>
                <Profile />
                <div className={styles.flexContainer}>
                    <div className={styles.icons}>
                        <SvgSpriteIcon name="linguaLogo" className={styles.logo} />
                        <span className={styles.equal}>=</span>
                        <SvgSpriteIcon name="windmill" className={styles.windmill} />
                        <span className={styles.plus}>+</span>
                        <SvgSpriteIcon name="quotes" className={styles.quotes} />
                    </div>
                    <p className={styles.text}>
                        Given the diversity of users, professions, countries and continents that
                        “spin” in the language learning process, a mill would be a suitable concept.
                        And to make it relevant to linguistics, it&apos;s four wings are replaced
                        with quotes.
                    </p>
                </div>
            </div>
        </section>
    )
}

export default Interview
