import React, { Component } from 'react'
import PT from 'prop-types'
import styles from './styles.module.scss'

class VisualizeData extends Component {
    constructor(props) {
        super(props)
        this.listRef = React.createRef()
        this.state = {
            visible: false,
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.fillData)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.fillData)
    }

    fillData = () => {
        if (window.pageYOffset + window.innerHeight > this.listRef.current.offsetTop)
            this.setState({ visible: true })
    }

    render() {
        return (
            <ul ref={this.listRef}>
                {Object.entries(this.props.object).map(([key, value]) => (
                    <li className={styles.dataItem} key={key}>
                        <div className={styles.parameter}>
                            <span className={styles.parameterName}>{key}</span>
                            <span className={styles.percents}>{value}%</span>
                        </div>
                        <div className={styles.visualContainer}>
                            <div
                                className={styles.inner}
                                style={{ width: this.state.visible ? `${value}%` : 0 }}
                            />
                        </div>
                    </li>
                ))}
            </ul>
        )
    }
}

VisualizeData.propTypes = {
    object: PT.object,
}

export default VisualizeData
