import React from 'react'
import classNames from 'classnames'

import SvgSpriteIcon from 'src/components/SvgSprite/SvgSpriteIcon'

import styles from './styles.module.scss'

const Idea = () => {
    const sectionContainerClass = classNames(styles.application, 'container')
    return (
        <section className={sectionContainerClass}>
            <span className={styles.pathText}>
                Application development from idea to implementation.
            </span>
            <div className={styles.iconsContainer}>
                <div className={styles.iconWrapper}>
                    <SvgSpriteIcon name="linguaIdea" className={styles.svg} />
                    <span>Idea</span>
                </div>
                <div className={styles.iconWrapper}>
                    <SvgSpriteIcon name="linguaResearch" className={styles.svg} />
                    <span>Research</span>
                </div>
                <div className={styles.iconWrapper}>
                    <SvgSpriteIcon name="linguaInterview" className={styles.svg} />
                    <span>Persona&apos;s Interview</span>
                </div>
                <div className={styles.iconWrapper}>
                    <SvgSpriteIcon name="linguaFlow" className={styles.svg} />
                    <span>User Flow</span>
                </div>
                <div className={styles.iconWrapper}>
                    <SvgSpriteIcon name="linguaWireframes" className={styles.svg} />
                    <span>Wireframes</span>
                </div>
                <div className={styles.iconWrapper}>
                    <SvgSpriteIcon name="linguaDesign" className={styles.svg} />
                    <span>Visual design</span>
                </div>
            </div>
            <div className={styles.idea}>
                <h4 className={styles.ideaTitle}>Idea</h4>
                <p>
                    An application for learning English using context. Designed for people who
                    already know the language well, but wishing to bring their knowledge to
                    perfection. The task was to create a corporate identity and distribute it to a
                    mobile application and landing page.
                </p>
            </div>
        </section>
    )
}

export default Idea
