import React, { Component } from 'react'
import classNames from 'classnames'

import SvgSpriteIcon from 'src/components/SvgSprite/SvgSpriteIcon'
import schema from 'src/images/optimized/png/linguamill/Flow.png'
import retinaSchema from 'src/images/optimized/png/linguamill/Flow@2x.png'

import styles from './styles.module.scss'

class Flow extends Component {
    constructor(props) {
        super(props)
        this.scrollDiv = React.createRef()
        this.state = {
            swiping: false,
        }
    }

    componentDidMount() {
        setTimeout(() => {
            const imageCenter =
                (this.scrollDiv.current.scrollWidth - this.scrollDiv.current.clientWidth) / 2
            this.scrollDiv.current.scrollLeft = imageCenter
        }, 500)
    }

    isSwiping = () => {
        this.setState({ swiping: true })
    }

    swipingStoped = () => {
        this.setState({ swiping: false })
    }
    render() {
        const touchedIconClass = classNames(styles.icon, styles.touched)
        return (
            <>
                <section className={styles.flow}>
                    <h4 className={styles.mainTitle}>User Flow</h4>
                    <div className={styles.a}>
                        <div
                            ref={this.scrollDiv}
                            className={styles.schemaContainer}
                            id="schemaContainer"
                            onTouchMove={this.isSwiping}
                            onTouchEnd={this.swipingStoped}
                        >
                            <img
                                className={styles.schema}
                                src={schema}
                                srcSet={`${retinaSchema} 2x`}
                                alt="Linguamill user flow schema"
                            />
                        </div>
                    </div>
                    <SvgSpriteIcon
                        name="linguaSwipe"
                        className={this.state.swiping ? touchedIconClass : styles.icon}
                    />
                </section>
            </>
        )
    }
}

export default Flow
