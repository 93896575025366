import React from 'react'
import classNames from 'classnames'

import LandingImg from 'src/images/optimized/png/linguamill/landing.png'
import retinaLandingImg from 'src/images/optimized/png/linguamill/landing@2x.png'

import styles from './styles.module.scss'

const Landing = () => {
    const containerClass = classNames('container', styles.relative)
    return (
        <section className={styles.landing}>
            <h4 className={styles.mainTitle}>Landing page</h4>
            <div className={containerClass}>
                <img src={LandingImg} srcSet={`${retinaLandingImg} 2x`} className={styles.image} />
                <div className={styles.shaeTop} />
                <div className={styles.shaeMiddle} />
                <div className={styles.shaeBottom} />
                <div className={styles.colorfull} />
                <div className={styles.variety} />
                <div className={styles.fun} />
            </div>
        </section>
    )
}

export default Landing
