import React from 'react'

import Jane from 'src/images/original/png/linguamill/Jane.png'
import Zoltan from 'src/images/original/png/linguamill/Zoltan.png'
import Bhavin from 'src/images/original/png/linguamill/Bhavin.png'

import styles from './styles.module.scss'

const Profile = () => {
    const users = [
        {
            photo: Jane,
            name: 'Jane Khang',
            years: 23,
            occupation: 'Student',
            location: 'New York, USA',
            about:
                'Jane is from Beijing. She is the last year of graduate studies and lives in her residence at the university. Jane knows English well in order to study at an American university, but some nuances and turns of speech are still inaccessible to her. She would like to bring her knowledge to perfection.',
            needs:
                'Jane would like to bring her knowledge to perfection. Jane knows English well in order to study at an American university, but some nuances and turns of speech  are still inaccessible to her. ',
        },
        {
            photo: Zoltan,
            name: 'Zoltan Stefanovicz',
            years: 34,
            occupation: 'IT Freelancer',
            location: 'Belgrade, Serbia',
            about:
                'Zoltan is a freelancer. He is engaged in the development  of information security systems. Most of his clients are residents of English- speaking countries. Zoltan knows English very well for programming, but some shades of spoken language are not very clear for him.',
            needs:
                'Zoltan wants to improve his language level to be able to talk not only on professional topics but also understanding the subtle points of humor and slang.',
        },
        {
            photo: Bhavin,
            name: 'Bhavin Pran',
            years: 48,
            occupation: 'Cardiac surgeon',
            location: 'Mumbai, India',
            about:
                'Bhavin is an experienced doctor who loves his profession. He studied English at school and is fluent in it, like most of his compatriots. But medicine  is changing rapidly, and new terms appear in it.',
            needs:
                'Bhavin reads professional medical literature, which contains many specific terms and their meanings. He needs to better understand the intricacies of the medical language.',
        },
    ]

    return (
        <ul className={styles.usersList}>
            {users.map(({ photo, name, years, occupation, location, about, needs }) => (
                <li key={name} className={styles.userItem}>
                    <div className={styles.person}>
                        <div className={styles.photo}>
                            <img src={photo} alt={name} />
                        </div>
                        <div>
                            <span className={styles.name}>{name}</span>
                            <div>
                                <span className={styles.fieldName}>Age: </span>
                                <span>{years} years old</span>
                            </div>
                            <div>
                                <span className={styles.fieldName}>Occupation: </span>
                                <span>{occupation}</span>
                            </div>
                            <div>
                                <span className={styles.fieldName}>Location: </span>
                                <span>{location}</span>
                            </div>
                        </div>
                    </div>
                    <p className={styles.about}>{about}</p>
                    <h5 className={styles.needsTitle}>Needs</h5>
                    <p className={styles.needs}>{needs}</p>
                </li>
            ))}
        </ul>
    )
}

export default Profile
