import React from 'react'
import classNames from 'classnames'

import styles from './styles.module.scss'

const Result = () => {
    const containerTitleClass = classNames('container', styles.mainTitle)
    return (
        <section>
            <h4 className={containerTitleClass}>Result</h4>
            <div className={styles.image} />
        </section>
    )
}

export default Result
