import React from 'react'

import VisualizeData from './VisualizeData.js'

import styles from './styles.module.scss'

const Research = () => {
    const data = {
        age: {
            '18-25 years old': 27,
            '26-35 years old': 48,
            '36-45 years old': 20,
            Other: 5,
        },
        country: {
            USA: 59,
            Asia: 25,
            Europe: 12,
            Other: 4,
        },
        services: {
            Two: 55,
            One: 30,
            'More than two': 15,
        },
        gender: {
            Male: 48,
            Female: 52,
        },
        reaching: {
            'In several weeks': 47,
            'In a month': 25,
            'More than month': 20,
            Other: 8,
        },
        using: {
            'Several times a day': 59,
            'Once a day': 25,
            'Several times a week': 12,
            'Once a week': 4,
        },
    }

    const { age, country, services, gender, reaching, using } = data

    return (
        <section className={styles.research}>
            <div className="container">
                <h4>UX Research</h4>
                <p>
                    In order to provide empathy to users and realize what problems they face with,
                    what motivates them in order to use language service. We interviewed 100 users.
                    We also have gathered some existing users feedbacks from the app store and play
                    store for understanding users needs. We have to bear in mind what is important
                    to the user at every moment of using this service, which is secondary. How the
                    system can help eventually.
                </p>
                <div className={styles.dataContainer}>
                    <div className={styles.dataList}>
                        <h5>What&apos;s your age?</h5>
                        <VisualizeData object={age} />
                    </div>
                    <div className={styles.dataList}>
                        <h5>Where do you live?</h5>
                        <VisualizeData object={country} />
                    </div>
                    <div className={styles.dataList}>
                        <h5>What&apos;s your gender?</h5>
                        <VisualizeData object={gender} />
                    </div>
                    <div className={styles.dataList}>
                        <h5>How fast do you reach the goal?</h5>
                        <VisualizeData object={reaching} />
                    </div>
                    <div className={styles.dataList}>
                        <h5>How often do you use services?</h5>
                        <VisualizeData object={using} />
                    </div>
                    <div className={styles.dataList}>
                        <h5>How many services do you use?</h5>
                        <VisualizeData object={services} />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Research
