import React from 'react'
import classNames from 'classnames'

import styles from './styles.module.scss'

const LinguaTitle = () => {
    const sectionContainerClass = classNames('container', styles.header)
    return (
        <section className={sectionContainerClass}>
            <h3 className={styles.mainTitle}>
                <span className={styles.break}>Linguamill Mobile App &</span> Brand Identity
            </h3>
        </section>
    )
}

export default LinguaTitle
