import React, { Component } from 'react'

import Header from 'src/components/Header'
import Footer from 'src/components/Footer'
import LinguaTitle from 'src/components/LinguamillCase/LinguaTitle'
import Idea from 'src/components/LinguamillCase/Idea'
import Research from 'src/components/LinguamillCase/Research'
import BaseContainer from 'src/components/BaseContainer'
import Interview from 'src/components/LinguamillCase/Interview'
import Flow from 'src/components/LinguamillCase/Flow'
import Wireframes from 'src/components/LinguamillCase/Wireframes'
import Design from 'src/components/LinguamillCase/Design'
import Guideline from 'src/components/LinguamillCase/Guideline'
import Result from 'src/components/LinguamillCase/Result'
import Landing from 'src/components/LinguamillCase/Landing'
import OtherCases from 'src/components/OtherCases'
import LetsTalk from 'src/components/LetsTalk'
import appBrief from '../../docs/App-brief.pdf'
import Meta from '../../components/Meta';

const metaData = {
    title: 'Linguamill Mobile App & Brand Identity',
    description: 'Calaton Studio we have many cases with completed works',
    keywords: 'Calaton Studio, cases',
}

class Linguamill extends Component {
    render() {
        return (
            <BaseContainer>
	            <Meta content={metaData} />
                <Header />
                <LinguaTitle />
                <Idea />
                <Research />
                <Interview />
                <Flow />
                <Wireframes />
                <Design />
                <Guideline />
                <Result />
                <Landing />
                <OtherCases filter="mobileApp" />
                <LetsTalk brief={{ name: 'App-brief', file: appBrief }} />
                <Footer />
            </BaseContainer>
        )
    }
}

export default Linguamill
